
:root{
    --primary-white: #fff;
    --primary-red: #f00;
    --primary-dark:rgba(0, 0, 0, 0.6);  
   }

body{
    font-family: "Open Sans", sans-serif;
    background-color: black;
  
}
.card-title{
    font: 500 1.25rem "roboto",lato;
}
.card-img-overlay .card-title{
    color: white;
    background-color: rgba(0,0,0,0.5);
}
.logo{
    width:13rem;
}
/* Menu link */
.navbar a.nav-link{
    font-size: 1rem;
    cursor: pointer;
    color: var(--primary-white) !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;   
}

.navbar .nav-item.active {
    border-left: #444 0.188rem solid; 
    
}

.nav-link:hover {
    border-bottom: 0.063rem solid var(--primary-red);
    transition: border 0.1s; 
}
/* CAROUSEL */
.carouselCaption{
    background: var(--primary-dark);
    padding: 2rem;
    font-weight: 400; 
    font-size: 2.5rem;  
}
.carouselItem{
    height:32rem;
    
}
/* Home page */
.jumbo{
    background-color: rgba(0,0,0,0.9)!important;
    color: var(--primary-white) !important; 
}
.jumbo2, .jumbo3, .jumbo4{
    background-color: var(--primary-white)!important;;
}
/* .product{
    background-color: rgba(0,0,0,0.9)!important;
} */
.slick-slide{
    margin: 0 0.20rem;
    
}
.slick-list{
    margin: 0;   
}
.slick-list *:focus{
    outline: none; 
}

footer{
    /* background: url("./components/assets/images/services.jpg"); */
    color: var(--primary-white) !important;  
}


